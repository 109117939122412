import classnames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

function PaginationLinks({
  pageCount = 1,
  activePageIndex = 0,
  baseLink = '/success-stories/',
  pageSubDir = '',
}) {
  return (
    <div className="pagination-wrapper row my-5" id="pagination-wrapper">
      {Array.from({ length: pageCount }).map((_, index) => (
        <Link
          to={`${index === 0 ? baseLink : baseLink + pageSubDir + (index + 1)}/`}
          className={classnames('page-selector', {
            selected: activePageIndex === index,
          })}
          id={index}>
          <p>{index + 1}</p>
        </Link>
      ))}
    </div>
  );
}

PaginationLinks.propTypes = {
  pageCount: PropTypes.number.isRequired,
  activePageIndex: PropTypes.number.isRequired,
  baseLink: PropTypes.string.isRequired,
  pageSubDir: PropTypes.string,
};

export default PaginationLinks;

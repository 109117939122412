import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';

function Filters({ selectedWpSlug = '' }) {
  const data = useStaticQuery(graphql`
    query TagFilters {
      allWpTag(sort: { databaseId: ASC }) {
        edges {
          node {
            databaseId
            slug
            name
          }
        }
      }
    }
  `);
  return (
    <section className="mb-5 py-5 section-pale" id="tags">
      <div className="container my-5">
        <h3 className="text-hero text-phone text-grey mt-3 mb-4">
          Filter Success Stories
        </h3>
        <div className="">
          <div className="tag-list" id="tag-list">
            <div className="row">
              {data.allWpTag.edges.map((edge, index) => {
                const { name, databaseId, slug } = edge.node;
                return (
                  <div
                    key={index + databaseId}
                    className={classnames('col-12 col-sm-6 col-lg-3 mb-3')}>
                    <Link
                      className={classnames('tag', {
                        selected: slug === selectedWpSlug,
                      })}
                      to={`/success-stories/${slug === selectedWpSlug ? '' : slug}/`}
                      id={`tag-${index}`}
                      wp-id={databaseId}>
                      <div className="label">
                        <p>{name}</p>
                        <span className="success-check">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-check2"
                            viewBox="0 0 16 16">
                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                          </svg>{' '}
                        </span>
                        <span className="success-close">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-x"
                            viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Filters.propTypes = {
  selectedWpSlug: PropTypes.string,
};

export default Filters;

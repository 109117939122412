const schema = `{
  "@context": "http://schema.org",
  "@type": "Product",
  "name": "Success Stories | Pandev Law",
  "description": "",
  "url": "https://www.pandevlaw.com/success-stories",
  "image": "https://pandevlaw.com/img/stock-exchange.jpg",
  "brand": "Pandev Law",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  },
  "sameAs": [
    "https://en-gb.facebook.com/pandevlaw/"
  ]
}`;

export default schema;
